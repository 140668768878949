import { isLocalDevelopment } from '@/utils/local-development';
import { isObject } from '@/utils/object';
import { reportSentryWarning } from '@/utils/sentry';
import { debounce } from 'lodash-es';
import { fetchUserSession } from './user-session';

let lastTimestamp = Date.now();

// Refresh Cognito user's data (all attributes: tenant, groups, permissions, etc)
// Adapted from https://github.com/aws-amplify/amplify-js/issues/3730#issuecomment-515205762
async function refreshUserData(reason: string) {
  if (isLocalDevelopment()) {
    // Log when refreshing user data and how long it's been since we last did it (or the page loaded)
    const currentTimestamp = Date.now();
    console.log(
      'Refreshing user data',
      `[${reason}]`,
      Math.round((currentTimestamp - lastTimestamp) / 1000)
    );
    lastTimestamp = currentTimestamp;
  }

  try {
    await fetchUserSession({ forceRefresh: true });
  } catch (error: unknown) {
    const expectedErrors = ['NetworkError', 'UserNotFoundException', 'NotAuthorizedException'];
    if (isObject(error) && expectedErrors.includes(error.code as string)) {
      // These issues are expected. No need to report them
      console.warn('Failed to refresh user session', error);
    } else {
      reportSentryWarning('Failed to refresh user session', { cause: error });
    }
  }
}

// Only refresh user data at most once every 10 seconds, and never wait longer than 30 seconds
// Note: probably not necessary right now b/c we only call this from one place, but a good safeguard
// in case we want to start calling this from more places within the app.
export const requestUserDataRefresh = debounce(refreshUserData, 10 * 1000, {
  maxWait: 30 * 1000,
  leading: true,
});
