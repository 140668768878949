import * as Sentry from '@sentry/react';
import { LimitedCache } from 'limited-cache';
import { isLocalDevelopment } from './local-development';

// Disable during SSR, local development and testing
const isSentryEnabled = !import.meta.env.SSR && !isLocalDevelopment() && !window.__endToEndTesting;

Sentry.init({
  dsn: 'https://5b966edf333244d1adccdfb9179bd1c4@o4504476209315840.ingest.sentry.io/4504963778347008',
  integrations: isSentryEnabled
    ? [
        // Enable tracing (performance monitoring). Conditional to avoid breaking SSR.
        Sentry.browserTracingIntegration(),
      ]
    : [],
  environment: import.meta.env.VITE_SENTRY_ENV, // Similar to env.MODE, but matches APIs (e.g. "dev", "prod", etc.)

  // Percentage of transactions to trace for performance monitoring
  tracesSampleRate: import.meta.env.MODE === 'production' ? 0.1 : 0,

  // Should we send errors to Sentry in this environment?
  enabled: isSentryEnabled,

  // Log deeply nested objects (default is 3 levels). Needed to inspect Zod schema issues, especially
  // with union types they can be deeply nested, e.g. https://redwoodmaterials.sentry.io/issues/4818389828
  normalizeDepth: 8,
});

if (typeof window !== 'undefined') {
  // Track page visibility in Sentry (impacts timers and anything performance related)
  Sentry.setTag('visibility', document.visibilityState);
  document.addEventListener('visibilitychange', () => {
    Sentry.setTag('visibility', document.visibilityState);
  });
}

const recentlyReportedCache = LimitedCache<boolean>({
  maxCacheTime: 60 * 1000, // Only report errors up to once a minute
});

export function reportSentryError(
  errorOrMessage: string | Error,
  details: { cause?: Error | unknown; [x: string]: unknown } = {}
) {
  // Create Error() from message if need be. Passing in an existing Error() object as the
  // first parameter -or- as the { cause } ensures Sentry gets an accurate stack trace
  const error = errorOrMessage instanceof Error ? errorOrMessage : new Error(errorOrMessage);
  const message = errorOrMessage instanceof Error ? errorOrMessage.message : errorOrMessage;

  const wasRecentlyReported = recentlyReportedCache.get(message) === true;

  // Log details for debugging
  const valuesToLog = wasRecentlyReported
    ? [message, details, { wasRecentlyReported }]
    : [message, details];
  console.warn(...valuesToLog);

  // Only report errors to Sentry up to once a minute
  if (wasRecentlyReported) return;
  recentlyReportedCache.set(message, true);

  // error.cause let's Sentry know what the source of this error is. If an Error() is passed
  // as the cause, Sentry will show an additional stack trace.
  if (!error.cause && details.cause) error.cause = details.cause;

  // Send error to Sentry with any associated context for debugging
  Sentry.captureException(error, { extra: details });
}

export function reportSentryWarning(
  errorOrMessage: string | Error,
  details: { cause?: Error | unknown; [x: string]: unknown } = {}
) {
  Sentry.withScope((scope) => {
    scope.setLevel('warning');
    reportSentryError(errorOrMessage, details);
  });
}

export function setSentryUser(user: Sentry.User | null) {
  if (typeof window !== 'undefined') {
    Sentry.setUser(user);
  }
}
