//
// This wrapper provides an in-memory fallback for LocalStorage/SessionStorage if it's not
// available for any reason (user settings, certain incognito modes, SSR, etc)
//
// It's basically a copy-paste of the "storage-factory" npm package with these changes:
// - Call checkIfSupported() only once (initially), not every time we try to get/set
// - Add a try-catch block to setItem() to catch QuotaExceededError and log what's taking up space
// - Expose .getItemSizes() method to get the size of each key in LocalStorage and log it for debugging
//

import { round, sum } from 'lodash-es';

export function safeStorage(getStorage: () => Storage): Storage {
  let inMemoryStorage: { [key: string]: string } = {};
  const isSupported = checkIfSupported();

  function checkIfSupported() {
    try {
      const testKey = '__some_random_key_you_are_not_going_to_use__';
      getStorage().setItem(testKey, testKey);
      getStorage().removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  // Get the size of each item in LocalStorage (sorted largest to smallest)
  // Useful for debugging when LocalStorage is full
  function getItemSizes() {
    const keyLengthPairs: [string, number][] = [];
    const keyCount = getStorage().length;
    for (let i = 0; i < keyCount; i++) {
      const keyName = getStorage().key(i);
      if (!keyName) continue;
      keyLengthPairs.push([keyName, (keyName + (getItem(keyName) || '')).length]);
    }
    return keyLengthPairs
      .sort((a, b) => b[1] - a[1])
      .map(([keyName, charLength]) => ({
        keyName,
        charLength,
        sizeInKb: charLengthToKb(charLength),
      }));
  }

  function clear(): void {
    if (isSupported) {
      getStorage().clear();
    } else {
      inMemoryStorage = {};
    }
  }

  function getItem(name: string): string | null {
    if (isSupported) {
      return getStorage().getItem(name);
    }
    // eslint-disable-next-line no-prototype-builtins
    if (inMemoryStorage.hasOwnProperty(name)) {
      return inMemoryStorage[name];
    }
    return null;
  }

  function key(index: number): string | null {
    if (isSupported) {
      return getStorage().key(index);
    } else {
      return Object.keys(inMemoryStorage)[index] || null;
    }
  }

  function removeItem(name: string): void {
    if (isSupported) {
      getStorage().removeItem(name);
    } else {
      delete inMemoryStorage[name];
    }
  }

  function setItem(name: string, value: string): void {
    if (isSupported) {
      try {
        getStorage().setItem(name, value);
        // throw new DOMException('QuotaExceededError', 'QuotaExceededError'); // For simulating errors
      } catch (e) {
        if (e instanceof DOMException && e.name === 'QuotaExceededError') {
          // Log total size and size of 10 largest LocalStorage items for debugging
          const keyValuesSizes = getItemSizes();
          const totalSize = round(sum(keyValuesSizes.map((o) => o.sizeInKb)));
          const setItemSize = charLengthToKb((name + value).length);
          console.warn(
            `Storage is full, total size of ${totalSize} KB before setItem("${name}", ...) failed to set ${setItemSize} KB`,
            keyValuesSizes.slice(0, 10) // Log top 10 largest values for debugging
          );
          throw e;
        }
      }
    } else {
      inMemoryStorage[name] = String(value); // not everyone uses TypeScript
    }
  }

  function length(): number {
    if (isSupported) {
      return getStorage().length;
    } else {
      return Object.keys(inMemoryStorage).length;
    }
  }

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    getItemSizes,
    get length() {
      return length();
    },
  };
}

// Source: https://stackoverflow.com/a/15720835/1546808
function charLengthToKb(length: number) {
  return round((length * 2) / 1024, 1);
}
