import { reportSentryWarning } from '@/utils/sentry';
import { showNotification } from '@/utils/show-notification';
import { deepEqualWithJsonStringify } from '@/utils/use-deep-equal';
import { UserStoreState } from './user-store';

export function logUserDataChanges(state: UserStoreState, prevState: UserStoreState) {
  if (
    state.auth.status === prevState.auth.status &&
    // @ts-expect-error Typescript complains b/c user property may not exist
    deepEqualWithJsonStringify(state.auth.user, prevState.auth.user)
  )
    return;

  // Log when user data changes
  if (state.auth.status === 'UNAUTHENTICATED') {
    console.log('User is not signed-in');
  } else if (state.auth.status === 'AUTHENTICATED') {
    console.log('User is authenticated', {
      type: state.auth.user.type,
      user: state.auth.user,
      payload: state.auth.payload,
    });
  }
}

export function showSignInFailedNotification(message?: string) {
  // Don't show user this generic error message that happens if they click
  // the Back button in the OAuth flow
  if (message === 'An error occurred while validating the state') return;

  // Improve error message if Redwood SSO user is denied access
  if (message && message.startsWith('User is not assigned to the client application')) {
    const oktaAppName =
      import.meta.env.MODE === 'production' ? 'REX Webapp' : 'REX Webapp (test env)';
    message = `Your Redwood Materials account has not been given access to the the "${oktaAppName}" Okta app. Please contact the IT helpdesk to be granted access.`;
  } else {
    // Report unexpected sign-in error for Redwood user
    reportSentryWarning(`Sign in failed: ${message}`);
  }

  showNotification({
    heading: 'Sign-in failed',
    message,
    type: 'error',
    duration: 7 * 1000, // Give users enough time to read error message
  });
}
