export function isLocalDevelopment() {
  return typeof window !== 'undefined' && location.hostname === 'localhost';
}

export function isLocalOrQAEnvironment(): boolean {
  const mode = import.meta.env.MODE;
  return mode === 'development' || mode === 'qa';
}

export function exposeOnWindow(obj: Record<string, unknown>) {
  if (typeof window !== 'undefined') Object.assign(window, obj);
}
