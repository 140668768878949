import { PagePath } from '@/components/breadcrumbs';
import { reportSentryWarning } from '@/utils/sentry';
import { KeysOfUnion, includes, truthy } from '@/utils/typescript';
import { ZodIssue, z } from 'zod';

export const expectedUserGroups = ['cp-admin', 'cp-view'] as const;

//
// Types of users on the REX webapp (used for access control)
// All users are Redwood employees
//

// Properites shared by all users in the Cognito user pool(s) used by this app
// Intentionally kept loose to only require attributes that must always exist
// We want to avoid unnecessarily strict schemas b/c if schema validation fails
// then pre-processing (e.g. for dealer codes) will not take place and downstream
// errors in React components are likely.
const baseUserSchema = z.object({
  id: z.string().uuid(),
  username: z.string(), // Not a pretty value, generated automatically by Cognito
  email: z.string().email(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  phone: z.string().optional(),
  // Note: a user may have other groups but we explicitly whittle them down to only
  // the ones we care about to avoid typos
  groups: z.array(z.enum(expectedUserGroups)),
});

// A union of all possible user types and their associated properties (derived from Cognito user attributes)
// The "type" property is an abstraction we use just to tell different user types apart
export const userSchema = z.discriminatedUnion('type', [
  // Unknown/invalid user (unable to determine user type based on Cognito attributes)
  baseUserSchema.extend({ type: z.literal('unknown'), tenant: z.string().optional() }),
  // Redwood user (via Okta)
  baseUserSchema.extend({ type: z.literal('redwood'), tenant: z.literal('redwood') }),
  // TODO -> add more redwood user types for more granular permissions
]);

export type UserSchema = z.infer<typeof userSchema>;
export type UserType = UserSchema['type'];
export type UserTenant = UserSchema['tenant'];
export type UserGroup = UserSchema['groups'][number];

export const cognitoAttributeMap: Record<string, Exclude<KeysOfUnion<UserSchema>, 'type'>> = {
  // Map of Cognito attribute names friendly user property names
  sub: 'id',
  'cognito:username': 'username',
  email: 'email',
  given_name: 'firstName',
  family_name: 'lastName',
  phone_number: 'phone',
  'custom:tenant': 'tenant',
};
export type CognitoIdTokenPayload = { [key in keyof typeof cognitoAttributeMap]: string } & {
  'cognito:groups'?: string[];
  vwgoa_user_permissions?: string;
  vwgoa_active_dealer_code?: string;
  iat: number;
};

// Miscellaneous user-type-specific metadata that's not stored in Cognito
// * dashboardPath -> where to send user after they login
export const userConfig: Record<UserType, { dashboardPath: PagePath }> = {
  // Redwood Employee (login via Okta)
  redwood: { dashboardPath: '/' },
  // Unknown user type
  unknown: { dashboardPath: '/' },
};

//
// Utils
//

export function getUserFromIdToken(idToken: CognitoIdTokenPayload): {
  user: UserSchema;
  validationIssues?: ZodIssue[];
} {
  // Map cognito attribute names to friendly property names
  const friendlyAttributeData = Object.fromEntries(
    Object.entries(idToken)
      .map(([cognitoName, value]) =>
        cognitoName in cognitoAttributeMap ? [cognitoAttributeMap[cognitoName], value] : undefined
      )
      .filter(truthy)
  );

  // Create user data object (not yet validated by schema)
  const userData: UserSchema = {
    type: getUserType(idToken),
    groups: getUserGroups(idToken),
    ...friendlyAttributeData,
  };

  // Report if unable to get a user's type. Means we likely haven't set a tenant in Cognito
  if (userData.type === 'unknown') {
    reportSentryWarning('Failed to determine user type', idToken);
  }

  // User attributes should match Zod schema
  const validationResult = userSchema.safeParse(userData);
  if (validationResult.success) {
    return {
      user: validationResult.data,
    };
  }

  // User attributes did not match Zod schema. Report a warning in Sentry but still
  // display a logged-in state so that user can log out. There may be runtime errors
  // but at least we know why and can fix them in Cognito.
  reportSentryWarning(`Cognito ${userData.type} user failed validation`, {
    userData,
    issues: validationResult.error.issues,
    payload: idToken,
  });
  return {
    user: userData,
    validationIssues: validationResult.error.issues,
  };
}

export function getUserType(idToken: CognitoIdTokenPayload): UserType {
  const tenant: string = idToken['custom:tenant'] || '';

  if (tenant === 'redwood') return 'redwood';
  return 'unknown';
}

export function getUserGroups(idToken: CognitoIdTokenPayload) {
  // Only return expected groups. Eventually we may do additional filtering by user type
  return (idToken['cognito:groups'] || []).filter((group) =>
    includes(expectedUserGroups, group)
  ) as UserGroup[];
}
