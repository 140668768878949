import { isLocalDevelopment } from '@/utils/local-development';
import { type CognitoUserPoolConfig } from '@aws-amplify/core/dist/esm/singleton/Auth/types';
import { Amplify } from 'aws-amplify';

const baseUrl =
  typeof window === 'undefined'
    ? '?'
    : `${isLocalDevelopment() ? 'http:' : 'https:'}//${location.host}`;

// Cognito user pool config for Redwood Materials employees authenticated via Okta
const redwoodOktaAwsAuthConfig: CognitoUserPoolConfig = {
  userPoolId: import.meta.env.VITE_USER_POOL_ID,
  userPoolClientId: import.meta.env.VITE_USER_POOL_WEB_CLIENT_ID,
  loginWith: {
    oauth: {
      domain: import.meta.env.VITE_OKTA_OAUTH_DOMAIN,
      scopes: ['openid'],
      redirectSignIn: [`${baseUrl}/callback/`],
      redirectSignOut: [`${baseUrl}/`],
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};

// Tell Amplify library what user pool to attempt to sign user into and to use to verify & refresh user's JWT
// Needs to be called once initially and then again if the user's preferred pool changes (not applicable to this app)
export function configureAmplifyUserPool() {
  const config = redwoodOktaAwsAuthConfig;

  // console.log('Configure amplify user pool', userPool);
  Amplify.configure({ Auth: { Cognito: config } });
}
