import { exposeOnWindow } from './local-development';
import { safeStorage } from './safe-storage';

//
// LocalStorage wrapper
// Get or set data as long as it passes validation
//

// LocalStorage with in-memory fallback during SSR and when unsupported by browsers
export const safeLocalStorage = safeStorage(() => window.localStorage);

export function setupStorage<T>(
  key: string,
  validate: (value: unknown, usage: 'get' | 'set') => T
) {
  return {
    getItem() {
      let value: unknown;
      try {
        value = JSON.parse(safeLocalStorage.getItem(key) as string);
      } catch (error) {
        /* empty */
      }
      return validate(value, 'get');
    },
    setItem(value: T) {
      safeLocalStorage.setItem(key, JSON.stringify(validate(value, 'set')));
    },
  };
}

exposeOnWindow({ _safeLocalStorage: safeLocalStorage }); // For debugging

//
// IndexedDB wrapper (unused)
// Creates an object store in a keyval-store IndexedDB store (managed by idb library)
//

// export function setupIdbStore(objectStoreName: string, onInit?: () => void) {
//   let dbPromise: Promise<IDBPDatabase> | undefined;

//   function withDb() {
//     if (dbPromise) return dbPromise;
//     dbPromise = openDB('keyval-store', 1, {
//       upgrade(db) {
//         db.createObjectStore(objectStoreName);
//       },
//     });
//     dbPromise.then(onInit);
//     return dbPromise;
//   }

//   async function idbGet(key: string) {
//     return (await withDb()).get(objectStoreName, key);
//   }
//   async function idbSet(key: string, val: Blob) {
//     return (await withDb()).put(objectStoreName, val, key);
//   }
//   async function idbDelete(key: string) {
//     return (await withDb()).delete(objectStoreName, key);
//   }
//   async function idbKeys() {
//     return (await withDb()).getAllKeys(objectStoreName);
//   }

//   return {
//     get: idbGet,
//     set: idbSet,
//     delete: idbDelete,
//     keys: idbKeys,
//   };
// }
