import { classNames } from '@/utils/class-names';
import { XCircleIcon, XMarkIcon } from '@heroicons/react/20/solid/index.js';
import { CheckCircleIcon } from '@heroicons/react/24/outline/index.js';

export type NotificationProps = {
  heading: string;
  message?: string;
  children?: React.ReactNode;
  type?: 'success' | 'error' | 'general';
  onDismiss: () => void;
};

export function Notification({
  heading,
  message,
  children,
  type = 'success',
  onDismiss,
}: NotificationProps) {
  return (
    <div className="flex w-full items-start">
      <div className="flex-shrink-0">
        {type === 'success' && (
          <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
        )}
        {type === 'error' && <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />}
      </div>
      <div className={classNames(' w-0 flex-1 pt-0.5', type !== 'general' && 'ml-3')}>
        <p className="text-sm font-medium text-gray-900">{heading}</p>
        {message && <p className="mt-1 text-sm text-gray-500">{message}</p>}
        {children}
      </div>
      <div className="ml-4 flex flex-shrink-0">
        <button
          type="button"
          className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={onDismiss}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
