import { useRef } from 'react';

// Prevent re-renders if Zustand state slice doesn't change, comparing the
// before & after values using JSON.stringify()
// Replaces useShallow() when we're returning nested objects (e.g. slice.user.*)
// Adapted from https://github.com/pmndrs/zustand/blob/main/src/react/shallow.ts
export default function useDeepEqualWithJsonStringify<S, U>(
  selector: (state: S) => U
): (state: S) => U {
  const prev = useRef<U>();

  return (state) => {
    const next = selector(state);
    return deepEqualWithJsonStringify(prev.current, next)
      ? (prev.current as U)
      : (prev.current = next);
  };
}

export function deepEqualWithJsonStringify(a: any, b: any) {
  return JSON.stringify(a) === JSON.stringify(b);
}
