import { Notification, NotificationProps } from '@/components/notification';
import { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import toast, { ToastPosition, useToasterStore } from 'react-hot-toast';
import waitForTimers from 'wait-for-timers';
import { reportSentryError } from './sentry';

// Temporary workaround for toasts failing to render if we attempt to open them before
// React has had a chance to do first render. 50ms was tested cross browser
const firstRenderPromise = new Promise((resolve) => setTimeout(resolve, 50));

// Keep track of the currently displayed toast ID
let currentToastId: string | null = null;

export function showNotification(
  props: Omit<NotificationProps, 'onDismiss'> & {
    position?: ToastPosition;
    duration?: number;
    uniqueId?: string; // To avoid duplicates
    onManuallyDismiss?: () => void;
    allowOnlyOne?: boolean; // New prop to allow only one toast at a time
  }
) {
  console.log('showNotification()', { heading: props.heading, message: props.message });

  // Handle case where toast is immediately dismissed
  let wasDismissedBeforeRender = false;
  let _dismissToast = () => {
    wasDismissedBeforeRender = true;
  };

  // Unfortunately, we can't call toast() until React has completed first render
  firstRenderPromise.then(() => {
    if (wasDismissedBeforeRender) return;

    // Dismiss the current toast if allowOnlyOne is true
    if (props.allowOnlyOne && currentToastId) {
      toast.dismiss(currentToastId);
    }

    // Show Notification toast using React Hot Toast
    const toastId = toast(
      <Notification
        {...props}
        onDismiss={() => {
          _dismissToast();
          if (props.onManuallyDismiss) props.onManuallyDismiss();
        }}
      >
        {props.children}
      </Notification>,
      {
        className:
          'overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 w-96 max-w-sm',
        position: props.position || 'top-right',
        duration: props.duration,
        id: props.uniqueId,
      }
    );

    // Set the current toast ID
    currentToastId = toastId;

    // Let caller dismiss toast
    _dismissToast = () => {
      toast.dismiss(toastId);
    };
  });

  return function dismissToast() {
    _dismissToast();
  };
}

// Very hacky way to get notification ids by temporarily rendering component to use hook, but it works
export function getNotificationIds(): Promise<string[]> {
  return new Promise((resolve) => {
    // Should never take longer than a few ms unless tab is hidden
    const cancelTimeout = waitForTimers([document.visibilityState === 'visible' ? 10 : 100], () => {
      reportSentryError('getNotificationIds render too slow');
      resolve([]);
    });

    // Temporarily render component to call custom hook & get toast ids
    const root = ReactDOM.createRoot(document.createElement('div'));
    root.render(
      <ToasterStoreComponent
        setIds={(ids: string[]) => {
          cancelTimeout();
          resolve(ids);
        }}
      />
    );
  });
}

// Only used to call custom hook, doesn't render anything
function ToasterStoreComponent({ setIds }: { setIds: (result: string[]) => void }) {
  const { toasts } = useToasterStore();
  setIds(toasts.map((toast) => toast.id));
  return <Fragment />;
}

// if (typeof window !== 'undefined') {
//   // @ts-ignore
//   window.getNotificationIds = getNotificationIds;

//   // setTimeout(() => {
//   //   showNotification({
//   //     heading: 'Successfully connected!',
//   //     duration: 1500,
//   //   });
//   // }, 1000);
// }
